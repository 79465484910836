export default class ScrollTo {
  constructor() {
    this.navigation = document.querySelector('.navigation');
    this.navigationTop = document.querySelector('.navigation__top');
    this.navigationButton = document.querySelector('.navigation__button');
    this.navigationMobile = document.querySelector('.navigation-mobile');
    this.closeButton = document.querySelector('.navigation__close-button');
    this.navListMobile = document.querySelector('.nav-mobile-list');
    this.header = document.querySelector('header');
    this.main = document.querySelector('.main');
    this.services = document.querySelector('.services');
    this.servicesNavContainer = document.querySelector('.services__nav-container');
    this.timeout = null;
    this.currNav = window.pageYOffset;
    this.init();
  }

  init = () => {
    this.checkPageNavigation();
    this.handleScroll();
    this.addEventListeners();
  }

  checkPageNavigation = () => {
    if (this.header.classList.contains('clear')) {
      this.navigation.classList.add('clear');
    }
  }

  addEventListeners = () => {
    let passiveIfSupported = false;

    try {
      window.addEventListener("test", null, Object.defineProperty({}, "passive", { get: function() { passiveIfSupported = { passive: true }; } }));
    } catch(err) {}

    window.addEventListener('scroll', this.onScroll, passiveIfSupported);
    this.navListMobile.addEventListener('click', this.toggleNavList, false);
    this.navigationButton.addEventListener(
        'click', this.handleNavigation, false);
    this.closeButton.addEventListener('click', this.handleNavigation, false);
  }

  toggleNavList = (event) => {
    event.currentTarget.classList.toggle('active');
  }

  handleNavigation = (event) => {
    this.navigationMobile.classList.toggle('active');
    document.body.classList.toggle('disable-scroll');
  }

  onScroll = (event) => {
    if (this.timeout) {
      window.cancelAnimationFrame(this.timeout);
    }
    this.timeout = window.requestAnimationFrame(this.handleScroll);
  }

  handleScroll = () => {
    let navOffsetHeight = this.navigation.offsetHeight;
    if (window.pageYOffset > 30 &&
        !this.navigation.classList.contains('hide') &&
        window.pageYOffset > this.currNav) {
      this.navigation.classList.add('hide');
    } else if (window.pageYOffset < this.currNav &&
        this.navigation.classList.contains('hide')) {
      this.navigation.classList.remove('hide');
    }

    if (this.services != null &&
        window.innerWidth <= 853 &&
        this.navigation.classList.contains('hide') &&
        window.pageYOffset > (this.header.offsetHeight - navOffsetHeight)) {
      this.servicesNavContainer.classList.add('active');
    }

    if (this.services != null &&
        window.innerWidth <= 853 &&
        !this.navigation.classList.contains('hide')) {
      this.servicesNavContainer.classList.remove('active');
    }

    if (this.header.classList.contains('clear') &&
        window.pageYOffset > navOffsetHeight) {
      this.navigation.classList.remove('clear');
    } else if (this.header.classList.contains('clear') &&
        window.pageYOffset < navOffsetHeight) {
      this.navigation.classList.add('clear');
    }

    this.currNav = window.pageYOffset;
  }
}
