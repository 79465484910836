import ScrollMagic from "scrollmagic";

export default class service {
  constructor() {
    this.contactCards = document.querySelectorAll('.contact__card-item');
    this.serviceNavigation = document.querySelector('.services__navigation');
    this.serviceNavButtons = document.querySelectorAll('.services__nav-button');
    this.navDropDownButtons = document.querySelectorAll('.dropdown-list__button');
    this.serviceList = document.querySelector('.services__list');
    this.serviceItems = document.querySelectorAll('.services__list-item');
    this.navigation = document.querySelector('.navigation');
    this.navigationBottom = document.querySelector('.navigation__bottom');
    this.dropDownSectionHeading = document.querySelector('.services__dropdown-section-heading');
    this.servicesNavContainer = document.querySelector('.services__nav-container');
    this.dropDownButton = document.querySelector('.services__dropdown-button');
    this.serviceCardsContainer = document.querySelectorAll('.services__cards-container');
    this.loadMoreButtons = document.querySelectorAll('.load-more');

    this.controller = null;
    this.scene = null;

    this.init();
  }

  init = () => {
    this.initScrollMagic();
    this.addEventListeners();
  }

  initScrollMagic = () => {
    this.controller = new ScrollMagic.Controller();
    let id;
    let pushFollowers = true;
    let sectionY;

    if (window.innerWidth <= 853) {
      id = "#services__navigation-container";
      pushFollowers = false;
      sectionY =
          (this.serviceNavigation.getBoundingClientRect().top +
          window.pageYOffset) - (this.navigationBottom.offsetHeight);
    } else {
      id = "#services__navigation";
      sectionY =
          (this.serviceNavigation.getBoundingClientRect().top +
          window.pageYOffset) - (this.navigationBottom.offsetHeight * 2);
    }

    let duration =
        this.serviceList.offsetHeight - this.serviceNavigation.offsetHeight;
    this.scene = new ScrollMagic.Scene({
        duration: duration,
        offset: sectionY
    })
    .setPin(id, {
      pushFollowers: pushFollowers
    })
    .addTo(this.controller);
  }

  addEventListeners = () => {
    for (let index = 0; index < this.loadMoreButtons.length; index++) {
      const element = this.loadMoreButtons[index];
      element.addEventListener('click', this.handleLoadMore, false);
    }
    for (let index = 0; index < this.contactCards.length; index++) {
      const element = this.contactCards[index];
      element.addEventListener('click', this.handleContactCard, false);
    }
    for (let index = 0; index < this.serviceNavButtons.length; index++) {
      const element = this.serviceNavButtons[index];
      element.addEventListener('click', this.handleScroll, false);
    }
    for (let index = 0; index < this.navDropDownButtons.length; index++) {
      const element = this.navDropDownButtons[index];
      element.addEventListener('click', this.handleDropdownScroll, false);
    }
    this.dropDownButton.addEventListener('click', this.handleDropdown, false);
    window.addEventListener('scroll', this.onScroll, false);
    window.addEventListener('resize', this.onResize, false);
  }

  onResize = () => {
    this.serviceNavigation = document.querySelector('.services__navigation');
    this.navigationBottom = document.querySelector('.navigation__bottom');
    this.serviceList = document.querySelector('.services__list');
    this.controller.updateScene(this.scene, true);
  }

  handleLoadMore = (event) => {
    const button = event.currentTarget;
    const contentContainer = button.nextElementSibling;
    const contentChildren = contentContainer.querySelectorAll('.services__cards');

    button.classList.toggle('active');
    let buttonText = button.firstElementChild.innerHTML;

    if (buttonText.match('Show')) {
      button.firstElementChild.innerHTML = 'Hide consultants';
    } else if (buttonText.match('Hide')) {
      button.firstElementChild.innerHTML = 'Show consultants';
    }

    if (contentContainer.classList.contains('hide')) {
      let contentHeight = 0;
      for (let index = 0; index < contentChildren.length; index++) {
        contentHeight += contentChildren[index].offsetHeight;
      }
      contentContainer.style.maxHeight = `${contentHeight}px`;
    } else {
      contentContainer.style.maxHeight = '0px';
    }

    contentContainer.classList.toggle('hide');
  }

  handleDropdown = (event) => {
    const button = event.currentTarget;
    const dropDownSection = button.nextElementSibling;
    dropDownSection.classList.toggle('active');
  }

  handleDropdownScroll = (event) => {
    for (let index = 0; index < this.navDropDownButtons.length; index++) {
      const element = this.navDropDownButtons[index];
      const elementContainer = element.parentElement;
      if (elementContainer.classList.contains('active')) {
        elementContainer.classList.remove('active');
      }
    }
    const button = event.currentTarget;
    const buttonContainer = button.parentElement;
    buttonContainer.classList.add('active');
    const dropDownSection = this.dropDownButton.nextElementSibling;
    dropDownSection.classList.toggle('active');
    this.handleScroll(event);
  }

  onScroll = () => {
    for (let index = 0; index < this.serviceItems.length; index++) {
      const element = this.serviceItems[index];
      if (this.checkVisible(element)) {
        let listItem = this.serviceNavButtons[index].parentNode;
        let listItemMobile = this.navDropDownButtons[index].parentNode;
        const buttonText = this.serviceNavButtons[index].innerHTML;
        if (!listItem.classList.contains('active')) {
          listItem.classList.add('active');
          listItemMobile.classList.add('active');
          this.dropDownSectionHeading.innerHTML = buttonText;
        }
      } else {
        let listItem = this.serviceNavButtons[index].parentNode;
        let listItemMobile = this.navDropDownButtons[index].parentNode;
        if (listItem.classList.contains('active')) {
          listItem.classList.remove('active');
          listItemMobile.classList.remove('active');
        }
      }
    }
  }

  checkVisible = (element) => {
    var rect = element.getBoundingClientRect();
    return (
      rect.top <= window.innerHeight / 2 &&
      rect.bottom >= window.innerHeight / 2
    )
  }

  handleScroll = (event) => {
    let button = event.currentTarget;
    let index = +button.dataset.index;
    let sectionElement = this.serviceItems[index - 1];
    let navigation = null;
    if (window.innerWidth <= 853) {
      navigation = this.navigationBottom.offsetHeight + 160;
    } else {
      navigation = this.navigation.offsetHeight + 80;
    }

    let sectionY =
        (sectionElement.getBoundingClientRect().top + window.pageYOffset) -
        (navigation);
    this.doScroll(sectionY, 1000);
  }

  easing = (t) => {
    return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1
  }

  doScroll = (elementY, duration) => {
    let startingY = window.pageYOffset;
    let diff = elementY - startingY;
    let start;
    let self = this;
    let newDuration = duration + (diff / 4);

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      let time = timestamp - start;
      let percent = Math.min(time / newDuration, 1);
      percent = self.easing(percent);
      let newY = startingY + diff * percent;
      window.scrollTo(0, newY);
      if (time < newDuration) {
        window.requestAnimationFrame(step)
      }
      self.currY = startingY + diff * percent;
    })
  }

  findAncestor = (el, sel) => {
    while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
    return el;
  }
}
