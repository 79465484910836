import { TimelineMax } from 'gsap/TweenMax';
import SplitText from './vendor/SplitText.js';

export default class headers {
  constructor() {
    this.heading = document.querySelector('.header__heading');
    this.tl = null;
    this.splitText();
  }

  splitText = () => {
    const split = new SplitText(this.heading, {
      type: "lines"
    });
    const lines = split.lines; //an array of all the divs that wrap each character

    TweenLite.set(this.heading, {
      perspective: 400
    });

    this.tl = new TimelineMax({
      delay: 1,
    });

    this.tl.addLabel('enter');

    this.tl.staggerFromTo(lines, .5, {
      yPercent: 30,
    },
    {
      yPercent: 0,
      ease: 'Circ.easeOut'
    }, 0.2, 'enter');

    this.tl.staggerFromTo(lines, .5, {
      opacity: 0,
    },
    {
      opacity: 1,
      ease: 'Power4.easeOut'
    }, 0.2, 'enter');

    this.tl.play();
  }
}
