import scrollTo from './lib/scrollTo';
import navigation from './lib/navigation';
import scrollRevealer from './lib/scrollRevealer';
import headers from './lib/headers';
import home from './lib/home';
import about from './lib/about';
import services from './lib/services';
import sectors from './lib/sectors';
import team from './lib/team';
import modal from './lib/modal';
import news from './lib/news';
import newsArticle from './lib/newsArticle';
import maps from './lib/maps';

class Main {
  constructor() {
    this.headingContainer = document.querySelector('.header__heading');
    this.homeContainer = document.querySelector('.home');
    this.aboutContainer = document.querySelector('.about');
    this.servicesContainer = document.querySelector('.services');
    this.sectorsContainer = document.querySelector('.subsectors');
    this.teamContainer = document.querySelector('.team');
    this.modal = document.querySelector('.team__details-modal');
    this.news = document.querySelector('.news');
    this.newsArticle = document.querySelector('.news-article');
    new scrollTo();
    new navigation();

    if (window.innerWidth > 539) {
      new maps();
    }
    new scrollRevealer();

    window.addEventListener('load', this.init, false);
  }

  init = () => {
    document.body.classList.add('is-active');

    if (this.headingContainer !== null) {
      new headers();
    }

    if (this.homeContainer !== null) {
      new home();
    }

    if (this.aboutContainer !== null) {
      new about();
    }

    if (this.servicesContainer !== null) {
      new services();
    }

    if (this.modal !== null) {
      new modal();
    }

    if (this.sectorsContainer !== null) {
      new sectors();
    }

    if (this.teamContainer !== null) {
      new team();
    }

    if (this.news !== null) {
      new news();
    }

    if (this.newsArticle !== null) {
      new newsArticle();
    }
  }
}

new Main();
