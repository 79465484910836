export default class team {
  constructor() {
    this.modal = document.querySelector('.team__details-modal');
    this.modalContainer = document.querySelector('.team__details');
    this.teamButtons = document.querySelectorAll('.section__button');
    this.searchInputs = document.querySelectorAll('.search__input');
    this.searchDropdowns = document.querySelectorAll('.search__dropdown');
    this.searchDropdownButtons =
        document.querySelectorAll('.search-dropdown__button');
    this.container = document.querySelector('.container');
    this.teamList = document.querySelectorAll('.section__team-list-item');
    this.resetButton = document.querySelector('.reset__button');
    this.container = document.querySelector('.container');
    this.addEventListeners();
  }

  addEventListeners = () => {
    for (let index = 0; index < this.searchInputs.length; index++) {
      const element = this.searchInputs[index];
      element.addEventListener('click', this.selectSearchInput, false);
      element.addEventListener('keyup', this.filterInput, false);
    }

    for (let index = 0; index < this.searchDropdownButtons.length; index++) {
      const button = this.searchDropdownButtons[index];
      if (button.classList.contains('search-id')) {
        button.addEventListener('click', this.handleSearchName, false);
      }
      if (button.classList.contains('search-sector')) {
        button.addEventListener('click', this.handleSearchSector, false);
      }
    }
    this.resetButton.addEventListener('click', this.resetSearch, false);
  }

  resetSearch = (event) => {
    const button = event.currentTarget;
    button.classList.add('hide');
    for (let index = 0; index < this.searchInputs.length; index++) {
      this.searchInputs[index].value = "";
    }
    const heading = document.querySelector('#results-heading');
    heading.innerHTML = 'Partners';
    for (let index = 0; index < this.teamList.length; index++) {
      const element = this.teamList[index];
      if (element.classList.contains('partner')) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  }

  handleSearchName = (event) => {
    this.hideDropDowns();
    const id = +event.currentTarget.dataset.id;
    const input = document.querySelector('#name-search-input');
    const heading = document.querySelector('#results-heading');
    heading.innerHTML = 'Results';
    for (let index = 0; index < this.teamList.length; index++) {
      const element = this.teamList[index];
      const elementId = +element.dataset.id;
      if (id !== elementId) {
        element.style.display = "none";
      } else {
        element.style.display = "block";
      }
    }
    input.value = event.currentTarget.innerHTML;
    this.resetButton.classList.remove('hide');
  }

  handleSearchSector = (event) => {
    this.hideDropDowns();
    const sector = event.currentTarget.dataset.sector;
    const input = document.querySelector('#sector-search-input');
    const heading = document.querySelector('#results-heading');
    heading.innerHTML = 'Results';
    for (let index = 0; index < this.teamList.length; index++) {
      const element = this.teamList[index];
      const elementSectors = element.dataset.sector.split(', ');
      if (elementSectors.indexOf(sector) > -1) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
    input.value = event.currentTarget.textContent;
    this.resetButton.classList.remove('hide');
  }

  hideDropDowns = () => {
    for (let index = 0; index < this.searchDropdowns.length; index++) {
      const element = this.searchDropdowns[index];
      if (element.classList.contains('active')) {
        element.classList.remove('active');
        this.searchDropdowns[index].parentElement.classList.remove('active');
      }
    }

    this.container.removeEventListener('click', this.handleContainer, false);
  }

  selectSearchInput = (event) => {

    const input = event.currentTarget;
    const dropDown = input.nextElementSibling;

    if (input.parentElement.classList.contains('active')) {
      this.hideDropDowns();
      input.parentElement.classList.remove('active');
      dropDown.classList.remove('active');
      input.blur();
    } else {
      this.hideDropDowns();
      input.parentElement.classList.add('active');
      dropDown.classList.add('active');
      this.container.addEventListener('click', this.handleContainer, false);
    }
  }

  handleContainer = (event) => {
    if (event.target.classList.contains('search__input')) {
      return;
    }

    if (event.target.classList.contains('search__dropdown')) {
      return;
    }

    if (event.target.classList.contains('search-dropdown__button')) {
      return;
    }

    this.hideDropDowns();
  }

  filterInput = (event) => {
    let input = event.currentTarget;
    let filter = input.value.toUpperCase();
    let dropdown = input.nextElementSibling;
    let item = dropdown.getElementsByTagName("li");

    for (let i = 0; i < item.length; i++) {
      let button = item[i].querySelector('button');
      if (button.innerHTML.toUpperCase().indexOf(filter) > -1) {
        item[i].style.display = "";
      } else {
        item[i].style.display = "none";
      }
    }
  }
}
