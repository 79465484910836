export default class modal {
  constructor() {
    this.teamList = document.querySelectorAll('.team-item');
    this.modal = document.querySelector('.team__details-modal');
    this.modalContainer = document.querySelector('.team__details');
    this.addEventListeners();
  }

  addEventListeners = () => {
    for (let index = 0; index < this.teamList.length; index++) {
      const element = this.teamList[index];
      const button = element.querySelector('.section__button');
      const image = element.querySelector('.team-image');
      button.addEventListener('click', this.openModal, false);
      if (image) {
        image.addEventListener('click', this.openModal, false);
      }
    }
  }

  findAncestor = (el, sel) => {
    while (
        (el = el.parentElement) &&
        !((el.matches || el.matchesSelector).call(el,sel)));
    return el;
  }

  openModal = event => {
    document.body.classList.add('disable-scroll');
    this.modal.classList.add('active');
    const teamDetailsContainer =
        this.modalContainer.querySelector('.team__details-container');
    const container = this.findAncestor(event.currentTarget, '.team-item');
    const infoElement = container.querySelector('.info__container');
    let modalData = this.getTeamInfo(infoElement);
    let modalFragment = this.createModal(modalData);
    teamDetailsContainer.innerHTML = modalFragment;
    let closeButton = document.querySelector('.close-button');
    closeButton.addEventListener('click', this.closeModal, false);
    this.modal.addEventListener('click', this.closeModal, false);
    this.modalContainer.classList.add('active');
  }

  getTeamInfo = element => {
    var data = {};
    data.fullname = element.querySelector('.name').innerHTML;
    data.name = data.fullname.split(' ')[0];
    data.image = element.querySelector('img').src;
    data.number = element.querySelector('.number').innerHTML;
    data.email = element.querySelector('.email').innerHTML;
    data.location = element.querySelector('.location').innerHTML;
    data.location = element.querySelector('.location').innerHTML;
    data.bio = element.querySelector('.bio').innerHTML;
    return data;
  }

  closeModal = () => {
    let closeButton = document.querySelector('.close-button');
    closeButton.removeEventListener('click', this.closeModal, false);
    this.modal.removeEventListener('click', this.closeModal, false);
    document.body.classList.remove('disable-scroll');
    this.modal.classList.remove('active');
    this.modalContainer.classList.remove('active');
    const teamDetailsContainer =
        this.modalContainer.querySelector('.team__details-container');
    teamDetailsContainer.innerHTML = "";
  }

  createModal = data => {
    return `<div class="team__details-section">
      <img src="${data.image}" alt="${data.fullname}">
      <h6 class="name">About ${data.name}</h6>
      <h6>Details</h6>
      <div class="contact-details">
        <p>Based in ${data.location}</p>
        <span>T:<span>${data.number}</span></span>
        <span>E:<a href="mailto:${data.email}">${data.email}</a></span>
      </div>
    </div>
    <div class="team__details-section">
      <h6 class="name">About ${data.name}</h6>
      <p>${data.bio}</p>
    </div>
    <button class="close-button">
      <span>close</span>
      <i class="close"></i>
    </button>`;
  }
}
