export default class newsArticle {
  constructor() {
    this.print = document.querySelector('.print-button');
    this.print.addEventListener('click', this.printPage, false);
    this.article = document.querySelector('.article');
    this.main = document.querySelector('.section.--news');
    this.navigationBottom = document.querySelector('.navigation__bottom');
    this.controller = null;
    this.scene = null;

    this.init();
  }

  init = () => {
    this.initScrollMagic();
  }

  printPage = () => {
    window.print();
  }

  initScrollMagic = () => {
    const tween = new TimelineMax();
    tween
      .from('#progress-bar', 1, {
        x: '-100%'
      }, 0)
      .to('#progress-bar', 1, {
        x: '0'
      }, 0);

    this.controller = new ScrollMagic.Controller();
    const id = "#progress-bar-container";

    let duration = this.main.offsetHeight;
    this.scene = new ScrollMagic.Scene({
        triggerElement: '#progress-bar-container',
        duration: duration,
        triggerHook: 0,
        offset: -(this.navigationBottom.offsetHeight),
        reverse: true
    })
    .setTween(tween)
    .setPin(id, {
      pushFollowers: false
    })
    .addTo(this.controller);
  }
}
