export default class ScrollTo {
  constructor() {
    this.scrollButtons = document.querySelectorAll('.scroll-button');
    this.sections = document.querySelectorAll('.section');
    this.navigation = document.querySelector('.navigation__bottom');
    this.scrollTopButton = document.querySelector('.scroll-top__button');
    this.container = document.querySelector('.container');
    this.addEventListeners();
  }

  addEventListeners = () => {
    for (let index = 0; index < this.scrollButtons.length; index++) {
      const button = this.scrollButtons[index];
      button.addEventListener('click', this.handleClick, false);
    }
    if (this.scrollTopButton != null) {
      this.scrollTopButton.addEventListener('click', this.scrollToTop, false);
    }
  }

  scrollToTop = () => {
    this.doScroll(0, 3000);
  }

  handleClick = (event) => {
    let button = event.currentTarget;
    let buttonIndex = +button.dataset.scrollIndex;
    let sectionElement = this.sections[buttonIndex];
    let sectionY =
        (sectionElement.getBoundingClientRect().top + window.pageYOffset);
    this.doScroll(sectionY, 1000);
  }

  easing = (t) => {
    return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1
  }

  doScroll = (elementY, duration) => {
    let startingY = window.pageYOffset;
    let diff = elementY - startingY;
    let start;
    let self = this;
    let newDuration = duration + (diff / 4);

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      let time = timestamp - start;
      let percent = Math.min(time / newDuration, 1);
      percent = self.easing(percent);
      let newY = startingY + diff * percent;
      window.scrollTo(0, newY);
      if (time < newDuration) {
        window.requestAnimationFrame(step)
      }
      self.currY = startingY + diff * percent;
    })
  }
}
