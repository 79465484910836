export default class about {
  constructor() {
    this.gridRows = document.querySelectorAll(".about__grid-row");
    this.sectionButtons = document.querySelectorAll(".about__grid-item");
    this.sectionMobileButtons = document.querySelectorAll(
      ".about-item__button"
    );
    this.gridContent = document.querySelectorAll(".about__grid-content");
    this.sectionsMobile = document.querySelectorAll(".about__grid-item-mobile");
    this.navigation = document.querySelector(".navigation__bottom");
    this.addEventListeners();
  }

  addEventListeners = () => {
    for (let index = 0; index < this.sectionButtons.length; index++) {
      const button = this.sectionButtons[index];
      button.addEventListener("click", this.handleClick, false);
    }

    for (let index = 0; index < this.sectionMobileButtons.length; index++) {
      const button = this.sectionMobileButtons[index];
      button.addEventListener("click", this.handleClickMobile, false);
    }
  };

  handleClickMobile = (event) => {
    const thisItem = event.currentTarget.parentNode;

    this.sectionsMobile.forEach((item) => {
      if (thisItem == item) {
        thisItem.classList.toggle("active");
        return;
      }

      item.classList.remove("active");
    });
  };

  handleClick = (event) => {
    for (let index = 0; index < this.gridContent.length; index++) {
      const element = this.gridContent[index];
      if (!element.classList.contains("hide")) {
        element.classList.add("hide");
      }
    }

    let button = event.currentTarget;
    let buttonIndex = +button.dataset.index;
    let gridContentContainer = button.parentNode.nextElementSibling;
    let contentItems = gridContentContainer.querySelectorAll(
      ".about__grid-content-item"
    );

    for (let index = 0; index < contentItems.length; index++) {
      contentItems[index].classList.remove("active");
    }

    gridContentContainer.classList.remove("hide");
    contentItems[buttonIndex].classList.add("active");

    let close = gridContentContainer.querySelector(".--close");
    close.addEventListener("click", this.closeContent, false);

    let next = gridContentContainer.querySelector(".--next");
    next.addEventListener("click", this.nextContent, false);
  };

  closeContent = (event) => {
    let button = event.currentTarget;
    let container = button.parentNode;
    let nextButton = container.querySelector(".--next");
    container.classList.add("hide");
    button.removeEventListener("click", this.closeContent, false);
    nextButton.removeEventListener("click", this.nextContent, false);
  };

  nextContent = (event) => {
    let button = event.currentTarget;
    let gridContentContainer = button.previousElementSibling;
    let contentItems = gridContentContainer.querySelectorAll(
      ".about__grid-content-item"
    );
    let index = 0;
    var ua = window.navigator.userAgent;
    var is_ie = /MSIE|Trident/.test(ua);

    for (let i = 0; i < contentItems.length; i++) {
      if (contentItems[i].classList.contains("active")) {
        index = i;
        contentItems[i].classList.remove("active");
      }
    }
    let newIndex = index + 1;

    if (!is_ie) {
      if (newIndex === 3) {
        newIndex = 0;
        this.toggleNextRow(button);
      }
      if (newIndex !== 3) {
        contentItems[newIndex].classList.add("active");
      }
    } else {
      if (newIndex === 3) {
        newIndex = 0;
      }
      contentItems[newIndex].classList.add("active");
    }
  };

  findAncestor = (el, sel) => {
    while (
      (el = el.parentElement) &&
      !(el.matches || el.matchesSelector).call(el, sel)
    );
    return el;
  };

  toggleNextRow = (button) => {
    // const parent = this.findAncestor(button, 'about__grid-row');
    const parent = button.closest(".about__grid-row");
    const prevContainer = parent.querySelector(".about__grid-content");
    prevContainer.classList.add("hide");
    let nextRow = parent.nextElementSibling;
    if (nextRow === null) {
      nextRow = this.gridRows[0];
    }
    const nextContainer = nextRow.querySelector(".about__grid-content");
    nextContainer.classList.remove("hide");
    let contentItems = nextContainer.querySelectorAll(
      ".about__grid-content-item"
    );
    contentItems[0].classList.add("active");

    let close = nextContainer.querySelector(".--close");
    close.addEventListener("click", this.closeContent, false);
    let next = nextContainer.querySelector(".--next");
    next.addEventListener("click", this.nextContent, false);
    let sectionY =
      nextContainer.getBoundingClientRect().top +
      window.pageYOffset -
      this.navigation.offsetHeight * 2;
    this.doScroll(sectionY, 1000);
  };

  easing = (t) => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };

  doScroll = (elementY, duration) => {
    let startingY = window.pageYOffset;
    let diff = elementY - startingY;
    let start;
    let self = this;
    let newDuration = duration + diff / 4;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      let time = timestamp - start;
      let percent = Math.min(time / newDuration, 1);
      percent = self.easing(percent);
      let newY = startingY + diff * percent;
      window.scrollTo(0, newY);
      if (time < newDuration) {
        window.requestAnimationFrame(step);
      }
      self.currY = startingY + diff * percent;
    });
  };
}
