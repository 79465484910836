export default class ScrollTo {
  constructor() {
    this.contactCards = document.querySelectorAll('.contact__card-item');
    this.addEventListeners();
  }

  addEventListeners = () => {
    for (let index = 0; index < this.contactCards.length; index++) {
      const element = this.contactCards[index];
      element.addEventListener('click', this.handleContactCard, false);
    }
  }

  findAncestor = (el, sel) => {
    while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
    return el;
  }

  handleContactCard = (event) => {
    let element = event.currentTarget;
    let parent = this.findAncestor(element, '.services__cards-container');
    if (!element.classList.contains('active')) {
      let height = parent.offsetHeight;
      parent.style.maxHeight = `${height + element.offsetHeight}px`;
    } else {
      let height = parent.offsetHeight;
      parent.style.maxHeight = `${height - element.offsetHeight}px`;
    }
    element.classList.toggle('active');
  }
}
