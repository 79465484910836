import ScrollReveal from 'scrollreveal';

export default class ScrollRevealer {
  constructor() {
    this.init();
  }

  init = () => {
    let options = {
      delay: 300,
      easing: 'ease-out',
      scale: 1,
      duration: 250,
      afterReveal: this.addAnimateClass
    }
    ScrollReveal().reveal('.js-animate', options);
  }

  addAnimateClass = (element) => {
    element.classList.add('active');
  }
}
