export default class home {
  constructor() {
    this.heading = document.querySelector('.home__heading');
    this.splitHeadings = document.querySelectorAll('.split__heading');
    this.sectionBackgrounds = document.querySelectorAll('.section__background');
    this.timelines = [];
    this.count = 0;

    this.init();
  }

  init = () => {
    this.splitTitle();
    for (let index = 0; index < this.splitHeadings.length; index++) {
      const element = this.splitHeadings[index];
      this.splitText(element, false);
      this.count = index + 1;
    }

    let passiveIfSupported = false;

    try {
      window.addEventListener("test", null, Object.defineProperty({}, "passive", { get: function() { passiveIfSupported = { passive: true }; } }));
    } catch(err) {}

    window.addEventListener('scroll', this.onScroll, passiveIfSupported);
  }

  splitTitle = () => {
    const split = new SplitText(this.heading, {
      type: "lines"
    });
    const lines = split.lines;
    TweenLite.set(this.heading, {
      perspective: 400
    });

    let tl = new TimelineMax({
      delay: .75,
    });

    tl.addLabel('enter');

    tl.staggerFromTo(lines, .5, {
      yPercent: 30,
    },
    {
      yPercent: 0,
      ease: 'Circ.easeOut'
    }, 0.2, 'enter');

    tl.staggerFromTo(lines, .5, {
      opacity: 0,
    },
    {
      opacity: 1,
      ease: 'Power4.easeOut'
    }, 0.2, 'enter');

    tl.play();
  }

  splitText = (element) => {
    const split = new SplitText(element, {
      type: "lines"
    });
    const lines = split.lines;
    TweenLite.set(element, {
      perspective: 400
    });

    let tl = new TimelineMax({
      delay: .75,
    });

    tl.addPause();

    tl.addLabel('enter');

    tl.staggerFromTo(lines, .5, {
      yPercent: 30,
    },
    {
      yPercent: 0,
      ease: 'Circ.easeOut'
    }, 0.2, 'enter');

    tl.staggerFromTo(lines, .5, {
      opacity: 0,
    },
    {
      opacity: 1,
      ease: 'Power4.easeOut'
    }, 0.2, 'enter');

    this.timelines.push(tl);
  }

  onScroll = () => {

    for (let i = 0; i < this.sectionBackgrounds.length; i++) {
      const element = this.sectionBackgrounds[i];
      if (this.checkVisible(element, window.innerHeight / 4)) {
        element.classList.add('active');
      }
    }

    for (let j = 0; j < this.splitHeadings.length; j++) {
      const element = this.splitHeadings[j];
      if (this.checkVisible(element, window.innerHeight / 5)) {
        this.count++;
        this.timelines[j].play();

        if (this.count === this.splitHeadings.length) {
          window.removeEventListener('scroll', this.onScroll, false)
        }
      }
    }
  }

  checkVisible = (element, threshold, mode) => {
    threshold = threshold || 0;
    mode = mode || 'visible';

    var rect = element.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    var above = rect.bottom - threshold < 0;
    var below = rect.top - viewHeight + threshold >= 0;

    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
  }
}
